import { IModel } from "../../../domain/domainModels/IModel";
import { Mutable } from "../../../domain/Types";

export function renameTaskLaterEventModelToMaybe(event: Mutable<IModel>) {
  if (event.eventName === "TaskLaterEventModel" && event.modelVersion === 1) {
    // Keep modelversion at 1 because we didn't increase the actual modelversion
    event.eventName = "TaskMaybeEventModel";
    event.maybeProperty = event.laterProperty;
    delete event.laterProperty;
  }
}
