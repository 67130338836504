import { IModel } from "../../../domain/domainModels/IModel";
import { Mutable } from "../../../domain/Types";

export function migrateGoalsToOutcomes(event: Mutable<IModel>) {
  if (
    event.projectModel &&
    event.projectModel.goals &&
    !event.projectModel.outcomes
  ) {
    event.projectModel.outcomes = event.projectModel.goals;
    delete event.projectModel.goals;
    event.projectModel.modelVersion = 2;
  }
}
