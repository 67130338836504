import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { DomainEventModel } from "./events/DomainEventModel";
import { IRepository } from "./IRepository";

export class EventStore {
  private currentlyReplayingEvents: readonly DomainEventModel[] = [];

  constructor(
    private readonly eventBus: DomainEventBus,
    private readonly eventRepo: IRepository<DomainEventModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async eventHandler(event: DomainEventModel): Promise<void> {
    // Skip saving events which are being replayed.
    // This is save as events are append only. This also still allows saving new events during replay
    if (!this.currentlyReplayingEvents.find((i) => i.uuid === event.uuid)) {
      await this.eventRepo.save(event);
    }
  }

  public async replayEvents(
    events?: readonly DomainEventModel[],
  ): Promise<void> {
    /* istanbul ignore next */ // TODO Cover getAll wihtin?
    this.currentlyReplayingEvents = events ?? (await this.eventRepo.getAll());
    const sortableEvents = this.currentlyReplayingEvents.slice();

    sortableEvents.sort((event1, event2) => {
      return event1.sequenceNumber - event2.sequenceNumber;
    });

    this.eventBus.disableSubscriberCheck();
    for (const event of sortableEvents) {
      await this.eventBus.publishEvent(event);
    }
    this.eventBus.enableSubscriberCheck();
    this.currentlyReplayingEvents = [];
  }

  public startSavingEvents(): void {
    this.eventBus.wildcardSubscribe((e) =>
      this.eventHandler(e as DomainEventModel),
    );
  }
}
