import { Component, SyntheticEvent } from "react";
import ErrorNotification from "../ErrorNotification/ErrorNotification";

import { EncryptionPasswordService } from "brainsupporter-core/lib/domain/EncryptionPasswordService";

import "./NewEncryptionPassword.css";

type NewEncryptionPasswordInputProps = {
  onPasswordSubmitted: (password: string) => Promise<void>;
};

type NewEncryptionPasswordInputState = {
  password: string;
  confirmationPassword: string;
  privacyPolicy: boolean;
  passwordsUnequal: boolean;
  passwordTooShort: boolean;
  errorMessages: string[];
};

/* istanbul ignore next */ // Coverage can be improved
class NewEncryptionPasswordInput extends Component<
  NewEncryptionPasswordInputProps,
  NewEncryptionPasswordInputState
> {
  passwordInput: HTMLInputElement | null = null;

  constructor(props: NewEncryptionPasswordInputProps) {
    super(props);
    this.state = {
      password: "",
      confirmationPassword: "",
      privacyPolicy: false,
      passwordsUnequal: false,
      passwordTooShort: false,
      errorMessages: [],
    };
  }

  private handleSubmitPassword = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!this.state.privacyPolicy) {
      this.setState({
        errorMessages: [
          "To use Brainsupporter you must agree to the privacy policy.",
        ],
      });
      return;
    }

    const password = this.state.password;
    const equal = password === this.state.confirmationPassword;
    const passwordLongEnough =
      password.length >= EncryptionPasswordService.minPasswordLength;
    const noSpaces = password.split(" ").length === 1;

    const errorMessages: string[] = [];

    if (equal && passwordLongEnough && noSpaces) {
      await this.props.onPasswordSubmitted(password);
    } else {
      if (!equal) {
        errorMessages.push("Passwords are unequal");
      }
      if (!passwordLongEnough) {
        errorMessages.push(
          "password has less characters than minimal password length. Minimal length is " +
            EncryptionPasswordService.minPasswordLength,
        );
      }
      if (!noSpaces) {
        errorMessages.push("password may not contain spaces");
      }
    }

    this.setState({
      passwordsUnequal: !equal,
      passwordTooShort: !passwordLongEnough,
      password: "",
      confirmationPassword: "",
      errorMessages,
    });
    this.passwordInput?.focus();
  };

  private setPassword = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  };

  private setConfirmationPassword = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    this.setState({ confirmationPassword: event.currentTarget.value });
  };

  private setPrivacyPolicy = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ privacyPolicy: !!event.currentTarget.checked });
  };

  override render() {
    return (
      <div className="newEncryptionPasswordInput">
        <form onSubmit={this.handleSubmitPassword}>
          <div className="explanation">
            <p>
              Brainsupporter uses client-side encryption to keep your data safe.
              This means your data is encrypted within your browser and never
              transmitted in an unencrypted form. It is stored encrypted on our
              server, but stored unencrypted on your computer. To access and
              decrypt your data, you will need the encryption password.
            </p>
            <p>
              In this step you can setup your encryption password. Here&apos;s
              what you need to know:
            </p>
            <ol>
              <li>
                Dual passwords: Set separate login and encryption passwords for
                better security.
              </li>
              <li>
                Use a strong password: Passwords need to be at least 8
                characters long.
              </li>
              <li>
                Remember your encryption password: Losing it means permanent
                loss of access to your data. Even we can&apos;t recover your
                data.
              </li>
              <li>
                Backup yourself: Safeguard your data by regularly creating
                backups using our export feature.
              </li>
            </ol>
            <p>
              You will automatically receive a backup of your data. If something
              goes wrong changing the password or you forget your new password,
              you will need this backup to restore your data.
            </p>
          </div>
          <div className="errorMessages">
            <ErrorNotification
              errorMessages={this.state.errorMessages}
              onChange={(newErrorMessages) => {
                this.setState({
                  errorMessages: newErrorMessages,
                });
              }}
            />
          </div>
          <div className="password1 formfield">
            <label>encryption password:</label>
            <input
              autoFocus
              type="password"
              value={this.state.password}
              onChange={this.setPassword}
              ref={(input) => {
                this.passwordInput = input;
              }}
            />
          </div>
          <div className="password2 formfield">
            <label>repeat encryption password:</label>
            <input
              type="password"
              value={this.state.confirmationPassword}
              onChange={this.setConfirmationPassword}
            />
          </div>
          <div className="privacy-policy formfield">
            <label>
              I agree with the &nbsp;
              <a href="/privacy-policy.html">privacy policy</a>:
            </label>
            <div>
              <input type="checkbox" onChange={this.setPrivacyPolicy} />
            </div>
          </div>
          <input type="submit" value="Set password" />
        </form>
      </div>
    );
  }
}

export default NewEncryptionPasswordInput;
