import * as UuidLib from "uuid";

import { TechnicalError } from "../errors/TechnicalError";
import { IModel } from "../domainModels/IModel";
import { EnvironmentUtils } from "../../util/EnvironmentUtils";
import { LogService } from "../../logging/LogService";

export class Validate {
  public static number(number: unknown) {
    const nr = number as number;
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!Validate.isNumber(nr)) {
      throw new TechnicalError("Validation Error. Not a number");
    }
  }

  // The package is not updated to use ESM, so use the code instead
  // https://github.com/jonschlinkert/is-number/blob/master/index.js
  public static isNumber(num: unknown): boolean {
    if (typeof num === "number") {
      return num - num === 0; // NOSONAR
    }
    /* istanbul ignore next */ // TODO: Write integration test with corrupt zip import
    if (typeof num === "string" && num.trim() !== "") {
      /* istanbul ignore next */ // corner case
      return Number.isFinite ? Number.isFinite(+num) : isFinite(+num);
    }
    /* istanbul ignore next */ // TODO: Write integration test with corrupt zip import
    return false;
  }

  public static boolean(value: unknown): void {
    /* istanbul ignore next */ // TODO: Write integration test with corrupt zip import
    const valid =
      value === true ||
      value === false ||
      toString.call(value) === "[object Boolean]";
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!valid) {
      throw new TechnicalError("Validation Error. Not a boolean");
    }
  }

  public static string(str: unknown): void {
    /* istanbul ignore next */ // TODO: Write integration test with corrupt zip import
    const valid = typeof str === "string" || str instanceof String;
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!valid) {
      throw new TechnicalError("Validation Error. Not a string");
    }
  }

  public static stringNotEmpty(str: unknown) {
    Validate.string(str);
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!str || (str as string).length === 0) {
      throw new TechnicalError("Validation Error. String is empty");
    }
  }

  public static stringArray(strs: unknown): void {
    Validate.array(strs);
    for (const str of strs as string[]) {
      Validate.string(str);
    }
  }

  public static array(arr: unknown): void {
    const valid = Array.isArray(arr);
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!valid) {
      throw new TechnicalError("Validation Error. Not an array");
    }
  }

  public static Uuidv4(uuid: string): void {
    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!UuidLib.validate(uuid) || UuidLib.version(uuid) !== 4) {
      throw new TechnicalError("Validation error on uuidV4");
    }
  }

  public static optionalEpoch(epoch: unknown): void {
    if (epoch === null || epoch === undefined) {
      return;
    }

    /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
    if (!Number.isInteger(epoch)) {
      throw new TechnicalError("Validation error. Epoch is not an integer");
    }

    Validate.number(epoch);
  }

  public static expectedProperties(
    model: IModel,
    expectedProperties: string[],
  ) {
    const modelProps = Object.getOwnPropertyNames(model);
    for (const prop of modelProps) {
      /* istanbul ignore if */ // TODO: Write integration test with corrupt zip import
      if (!expectedProperties.includes(prop)) {
        const modelName = model.constructor.name;
        const errMsg = `Validation error. Property ${prop} not expected on model ${modelName}`;
        if (EnvironmentUtils.isProduction()) {
          void LogService.getInstance().logWarningMessage(errMsg);
        } else {
          // TODO: This woudld still break canary, but we want it to be very visible. if can be removed if visible by user permission popup en warn to logservice
          throw new TechnicalError(errMsg);
        }
      }
    }
  }
}
