import { ProjectModel } from "../../../domainModels/ProjectModel";
import { TaskModel } from "../../../domainModels/TaskModel";

export class CompleteProjectFeedbackModel {
  public project: ProjectModel;
  public tasksCompleted: readonly TaskModel[] = [];
  public tasksNotCompleted: readonly TaskModel[] = [];

  constructor(project: ProjectModel) {
    this.project = project;
  }
}
