import { BrowserOrNode } from "./BrowserOrNode";

export class EnvironmentUtils {
  // The actual version gets replaced in the javascript buildoutput by a script
  private static readonly versionString: string = "[replaceByBuild]";

  public static getFullVersion(): string {
    return EnvironmentUtils.versionString;
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static getVersionNumber() {
    return EnvironmentUtils.getFullVersion().split("-")[0];
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isProduction() {
    return EnvironmentUtils.getFullVersion().split("-").length == 2;
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isCanary() {
    return EnvironmentUtils.getFullVersion().includes("-canary");
  }

  // Is alos dev
  /* istanbul ignore next */ // Currently not used during integration test
  public static isAcceptance() {
    // TODO: This would be true on the master branch on localhost. Can this be improved?
    return EnvironmentUtils.getFullVersion().includes("-master");
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static isDev() {
    return !EnvironmentUtils.isProduction() && !EnvironmentUtils.isCanary();
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static getHostname() {
    if (BrowserOrNode.isBrowser()) {
      return window.location.hostname;
      // otherwise we are in the api
    } else if (EnvironmentUtils.isProduction()) {
      return "brainsupporter.com";
    } else if (EnvironmentUtils.isCanary()) {
      return "gray-tree-01c1bf703-canary.westeurope.2.azurestaticapps.net";
    } else if (process.env.WEBSITE_HOSTNAME?.includes("localhost:")) {
      return process.env.WEBSITE_HOSTNAME;
    } else if (EnvironmentUtils.isAcceptance()) {
      return "blue-plant-0c46d0403.1.azurestaticapps.net";
    } else {
      return;
    }
  }

  /* istanbul ignore next */ // Currently not used during integration test
  public static getEnvironment() {
    if (EnvironmentUtils.isProduction()) {
      return "production";
    } else if (EnvironmentUtils.isCanary()) {
      return "canary";
    } else if (
      BrowserOrNode.isBrowser() &&
      window.location.hostname === "localhost"
    ) {
      return "localhost";
    } else if (process.env.WEBSITE_HOSTNAME?.includes("localhost:")) {
      return "localhost";
    } else if (EnvironmentUtils.isAcceptance()) {
      return "acceptance";
    } else {
      return;
    }
  }
}
