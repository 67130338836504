import { ModelCloner } from "../../util/ModelCloner";
import { ProjectModel } from "../domainModels/ProjectModel";
import { Validate } from "../validators/Validate";

export class ProjectViewModel extends ProjectModel {
  // TODO-RAS: Make readonly
  public displayId = 0; // Projects are not being stored anymore, only events

  /* istanbul ignore next */ // TODO: Shouldn't this be used during integration?
  public cloneWithoutViewProperties(): ProjectModel {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clone = ModelCloner.clone(this) as any;
    delete clone.displayId;
    return clone;
  }

  /* istanbul ignore next */ //TODO: See if fixing TODO's in UpdateProjectBotCommand fixes this if we
  protected override validateExpectedProperties() {
    Validate.expectedProperties(
      this,
      ProjectModel.expectedProperties.concat("displayId"),
    );
  }

  // TODO: Consult Niek. Prevent accidental saving of viewmodels? But is kinda breaks inheritance. How to prevent saving displayID and other view properties?
  // Prevent accidental saving of viewmodels
  // public override validate(intended = false) {
  //   if (!intended) {
  //     throw new TechnicalError(
  //       "Unintended validation of a viewmodel. Is it being saved?",
  //     );
  //   }
  //   super.validate();
  // }
}
