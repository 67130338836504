import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { LogService } from "../logging/LogService";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { HTTPResponseError } from "../util/HTTPResponseError";
import { RetryHelper } from "../util/RetryHelper";
import { AbstractRepository } from "./AbstractRepository";

/* eslint-disable @typescript-eslint/no-explicit-any */

/* istanbul ignore next */ // Don't count coverage as we only need to test the usage of retryFunction
export class ResilientRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private readonly retryHelper;

  constructor(
    repository: IRepository<T>,
    logService: LogService,
    delays: number[],
  ) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    this.retryHelper = new RetryHelper(repository, delays, logService);
  }

  public save(...params: any[]): Promise<timestamp> {
    return this.retryHelper.Retry("save", params, this.isRetryableError);
  }

  public deleteAll(...params: any[]): Promise<timestamp> {
    return this.retryHelper.Retry("deleteAll", params, this.isRetryableError);
  }

  public delete(...params: any[]): Promise<timestamp> {
    return this.retryHelper.Retry("delete", params, this.isRetryableError);
  }

  public getAll(...params: any[]): Promise<readonly T[]> {
    return this.retryHelper.Retry("getAll", params, this.isRetryableError);
  }

  public override tryGetByUuid(...params: any[]): Promise<T | undefined> {
    return this.retryHelper.Retry(
      "tryGetByUuid",
      params,
      this.isRetryableError,
    );
  }

  public getTimestamp(...params: any[]): Promise<timestamp> {
    return this.retryHelper.Retry(
      "getTimestamp",
      params,
      this.isRetryableError,
    );
  }

  public async import(...params: any[]): Promise<timestamp> {
    return this.retryHelper.Retry("import", params, this.isRetryableError);
  }

  private isRetryableError(error: HTTPResponseError) {
    // Narrow down specific errors if needed here
    if (error?.response?.status == 401) {
      // TODO: Unittest
      return false;
    } else {
      return true;
    }
  }
}
