import { AbstractRepository } from "./AbstractRepository";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { TechnicalError } from "../domain/errors/TechnicalError";

export class InMemoryRepository<T extends IIdentifiable>
  extends AbstractRepository<T>
  implements IRepository<T>
{
  private inMemoryModels: T[] = [];
  private currentTimestamp: timestamp = DateTimeUtils.getCurrentTimestamp();

  public getAll(): Promise<readonly T[]> {
    return Promise.resolve(this.inMemoryModels);
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    return this.inMemoryModels.find((m) => m.uuid === uuid);
  }

  public async save(model: T): Promise<timestamp> {
    const index = this.inMemoryModels.findIndex((m) => m.uuid === model.uuid);
    if (index === -1) {
      this.inMemoryModels.push(model);
    } else {
      this.inMemoryModels[index] = model;
    }

    return this.writeTimestamp();
  }

  public async deleteAll(): Promise<timestamp> {
    this.inMemoryModels = [];
    return this.writeTimestamp();
  }

  public async getTimestamp(): Promise<timestamp> {
    return Promise.resolve(this.currentTimestamp);
  }

  /* istanbul ignore next */ // Not used during integration
  public async delete(uuid: string): Promise<timestamp> {
    const index = this.inMemoryModels.findIndex((m) => m.uuid === uuid);
    if (index === -1) {
      throw new TechnicalError(
        "Trying to delete something that isn't there: " + uuid,
      );
    } else {
      this.inMemoryModels.splice(index, 1);
    }
    return this.getTimestamp();
  }

  public async import(models: readonly T[]): Promise<timestamp> {
    this.inMemoryModels = models.slice();
    return this.getTimestamp();
  }

  private async writeTimestamp(): Promise<timestamp> {
    this.currentTimestamp = DateTimeUtils.getCurrentTimestamp();
    return this.getTimestamp();
  }
}
