import { ConfigurationManager } from "../config/ConfigurationManager";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { UserModel } from "./domainModels/UserModel";
import { EncryptionService } from "./EncryptionService";
import { bcryptGenSalt, timestamp } from "../domain/Types";
import { ISecureCache } from "../util/ISecureCache";
import { TextUtils } from "../util/TextUtils";
import { TechnicalError } from "./errors/TechnicalError";
import { FunctionalError } from "./errors/FunctionalError";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { SingleRepository } from "../repository/SingleRepository";
import { LogService } from "../logging/LogService";
import { ModelCloner } from "../util/ModelCloner";

export class EncryptionPasswordService {
  public static bcryptFactor = 12; // Reduced in tests
  public static minPasswordLength = 8;

  private static passwordVerificationPhrase = "Is my password correct?";
  public static encryptionKeyTimestamp: timestamp | null = null;

  constructor(
    private readonly logService: LogService,
    private readonly configurationManager: ConfigurationManager,
    private readonly encryptionService: EncryptionService,
    private readonly userRepository: SingleRepository<UserModel>,
    private readonly bcryptGenSalt: bcryptGenSalt,
    private readonly passwordSecureCache: ISecureCache,
    private readonly encryptionKeySecureCache: ISecureCache,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }
  private encryptionPasswordCacheKey = "encryptionPassword";

  public async verifyExistingEncryptionPassword(
    email: string,
    encryptionPassword: string,
    user: UserModel,
  ): Promise<void> {
    if (
      !(await this.isEncryptionPasswordValid(
        email,
        encryptionPassword,
        user.userPasswordEncryptionKeySalt,
        user.encryptedPasswordVerificationPhrase,
      ))
    ) {
      const error = new FunctionalError("Encryption password is incorrect.");
      await this.logService.logWarning(error);
      throw error;
    }
    const keyTimestamp = user.encryptedDataEncryptionKeyTimestamp;

    await this.storeEncryptionPasswordInCache(
      email,
      encryptionPassword,
      keyTimestamp,
    );
  }

  private async isEncryptionPasswordValid(
    email: string,
    encryptionPassword: string,
    userPasswordEncryptionKeySalt: string,
    encryptedPasswordVerificationPhrase: string,
  ): Promise<boolean> {
    const userEncryptionKey = await this.getUserEncryptionKey(
      email,
      encryptionPassword,
      userPasswordEncryptionKeySalt,
    );
    let decryptedPasswordVerifyPhrase = "";

    try {
      const decryptedPasswordVerifyBytes = EncryptionService.decryptBytes(
        encryptedPasswordVerificationPhrase,
        userEncryptionKey,
      );
      decryptedPasswordVerifyPhrase = TextUtils.bytesToString(
        decryptedPasswordVerifyBytes,
      );
    } catch (error) {
      /* istanbul ignore next */ // istanbul bug. The line is covered in unit, but not reported
      return this.handleIncorrectPassword();
    }

    /* istanbul ignore next */ // Can not be tested because wrong password is
    // usually catched in condition above. This is only a fall-back scenario.
    if (
      decryptedPasswordVerifyPhrase !==
      EncryptionPasswordService.passwordVerificationPhrase
    ) {
      return this.handleIncorrectPassword();
    }
    return true;
  }

  private handleIncorrectPassword() {
    // Clear the cache so we can re-enter the password when it is wrong.
    this.passwordSecureCache.clearCache();
    return false;
  }

  public async getEncryptionPassword(email: string): Promise<string | null> {
    const passwordUint8 = await this.passwordSecureCache.getCache(
      this.getCacheKey(email),
    );
    /* istanbul ignore next */ // Can't test in integration test. Password gets cached automatically.
    if (!passwordUint8) {
      return null;
    }

    return TextUtils.bytesToString(passwordUint8);
  }

  // only called from web package, not in integration test
  /* istanbul ignore next */
  public isEncryptionPasswordCached(email: string): boolean {
    return this.passwordSecureCache.cacheKeyExists(this.getCacheKey(email));
  }

  public async resetSaltAndKeys(): Promise<void> {
    this.encryptionKeySecureCache.clearCache();

    const email = this.configurationManager.getEmail();
    const password = await this.getEncryptionPassword(email);
    let user = await this.userRepository.get();

    /* istanbul ignore if */ // Can't be tested in integration, because password is always set
    if (!password) {
      throw new TechnicalError(
        "Can't reset salt and keys with no existing password.",
      );
    }
    user = await this.generateEncryptionKeysInUserModel(email, password, user);
    EncryptionPasswordService.encryptionKeyTimestamp =
      user.encryptedDataEncryptionKeyTimestamp;

    await this.userRepository.save(user);
  }

  public async getDataEncryptionKey(user?: UserModel): Promise<Uint8Array> {
    const email = this.configurationManager.getEmail();
    const cacheKey = `EncryptedRepository.getEncryptionKey(${email})`;
    return this.encryptionKeySecureCache.getCacheOrResolve(
      cacheKey,
      async () => {
        const password = await this.getEncryptionPassword(email);
        user ??= await this.userRepository.tryGet();
        /* istanbul ignore if */ // Can't be tested in integration, because user is always set
        if (!user) {
          throw new TechnicalError(
            "Can't getDataEncryptionKey with no existing user.",
          );
        }

        /* istanbul ignore if */ // Can't be tested in integration, because password is always set
        if (!password) {
          throw new TechnicalError(
            "Can't getDataEncryptionKey with no existing password.",
          );
        }

        const userEncryptionKey = await this.getUserEncryptionKey(
          email,
          password,
          user.userPasswordEncryptionKeySalt,
        );
        const dataEncryptionKey = EncryptionService.decryptBytes(
          user.encryptedDataEncryptionKey,
          userEncryptionKey,
        );
        return dataEncryptionKey;
      },
    );
  }

  public validatePasswordRequirements(password: string): void {
    if (password.length < EncryptionPasswordService.minPasswordLength) {
      throw new FunctionalError(
        "password has less characters than minimal password length. Minimal length is " +
          EncryptionPasswordService.minPasswordLength,
      );
    }
  }

  public async changeEncryptionPassword(
    newEncryptionPassword: string,
  ): Promise<void> {
    this.validatePasswordRequirements(newEncryptionPassword);

    const email = this.configurationManager.getEmail();
    const existingDataEncryptionKey = await this.getDataEncryptionKey(); // decrypted with old password
    const keyTimestamp = await this.storeDataEncryptionKey(
      email,
      newEncryptionPassword,
      existingDataEncryptionKey,
    ); // encrypts with new password
    await this.storeEncryptionPasswordInCache(
      email,
      newEncryptionPassword,
      keyTimestamp,
    );
  }

  private async storeDataEncryptionKey(
    email: string,
    encryptionPassword: string,
    existingDataEncryptionKey: Uint8Array,
  ): Promise<timestamp> {
    let user = await this.userRepository.get();

    this.encryptionService.clearCache(); // remove old password from cache
    user = await this.generateEncryptionKeysInUserModel(
      email,
      encryptionPassword,
      user,
      existingDataEncryptionKey,
    );
    await this.userRepository.save(user);
    const keyTimestamp = user.encryptedDataEncryptionKeyTimestamp;

    /* istanbul ignore next */ // should never happen
    if (keyTimestamp === null) {
      throw new Error("Timestamp of encryption key is empty");
    }

    return keyTimestamp;
  }

  private getCacheKey(email: string): string {
    return this.encryptionPasswordCacheKey + "." + email;
  }

  /**
   * Encrypts the data encryption key with a user password,
   * so we can keep the data encryption key the same when the user changes
   * his password.
   *
   * This is like a text file with a crypto seed in an encrypted zip. You can
   * change the password by changing the password of the zip file, but the
   * crypto seed will stay the same.
   */
  public async generateEncryptionKeysInUserModel(
    email: string,
    password: string,
    userModel: UserModel,
    existingDataEncryptionKey: Uint8Array | null = null,
  ): Promise<UserModel> {
    const userPasswordEncryptionKeySalt = await this.bcryptGenSalt(
      EncryptionPasswordService.bcryptFactor,
    );

    const userEncryptionKey = await this.getUserEncryptionKey(
      email,
      password,
      userPasswordEncryptionKeySalt,
    );

    const dataEncryptionKey =
      existingDataEncryptionKey ||
      this.encryptionService.generateDataEncryptionKey();

    const encryptedDataEncryptionKey = EncryptionService.encryptBytes(
      dataEncryptionKey,
      userEncryptionKey,
    );
    const encryptedDataEncryptionKeyTimestamp =
      DateTimeUtils.getCurrentTimestamp();

    // Generate the password verification phrase to check password correctness
    const encryptedPasswordVerificationPhrase = EncryptionService.encryptBytes(
      TextUtils.stringToBytes(
        EncryptionPasswordService.passwordVerificationPhrase,
      ),
      userEncryptionKey,
    );

    return ModelCloner.updateValues(userModel, {
      userPasswordEncryptionKeySalt,
      encryptedDataEncryptionKey,
      encryptedDataEncryptionKeyTimestamp,
      encryptedPasswordVerificationPhrase,
    });
  }

  private async getUserEncryptionKey(
    email: string,
    password: string,
    salt: string,
  ): Promise<Uint8Array> {
    return this.encryptionService.convertPasswordToKey(email, password, salt);
  }

  public async storeEncryptionPasswordInCache(
    email: string,
    encryptionPassword: string,
    keyTimestamp: timestamp | null,
  ) {
    // Question: why store both password and encryptionKey in secureCache? We only need encryptionKey
    // Answer: it is more secure to story only the encryptionKey in local storage and store encryptionKey
    // only in memory, because localstorage data is more likely to be leaked because it is persisted longer.
    // And with just the encryption password you can not decrypt data. You also need the hash that is stored
    // on azure and can only be accessed with the github login.

    /* istanbul ignore next */ // should never happen
    if (keyTimestamp === null) {
      throw new Error("Timestamp of encryption key is empty");
    }

    const passwordBytes = TextUtils.stringToBytes(encryptionPassword);

    await this.passwordSecureCache.putCache(
      this.getCacheKey(email),
      passwordBytes,
    );

    EncryptionPasswordService.encryptionKeyTimestamp = keyTimestamp;
  }
}
