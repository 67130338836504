import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { ModelCloner } from "../../../util/ModelCloner";
import { TextUtils } from "../../../util/TextUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { AutoContextService } from "../../AutoContextService";
import { ProjectModel } from "../../domainModels/ProjectModel";
import { FunctionalError } from "../../errors/FunctionalError";
import { ProjectService } from "../../ProjectService";
import { SmartCaptureModel } from "../../SmartCapture/SmartCaptureModel";
import { SmartGenerator } from "../../SmartCapture/SmartGenerator";
import { SmartParser } from "../../SmartCapture/SmartParser";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class UpdateTaskBotCommand implements IExecutableBotCommand {
  public readonly commandName = "ut";
  public readonly description = "Update a task";
  public readonly smartButtonText = "Update task";
  public readonly argumentDescription =
    "<number> task description <@contexts> <+projects> <urls>";

  constructor(
    private readonly autoContextService: AutoContextService,
    private readonly taskService: TaskService,
    private readonly projectService: ProjectService,
    private readonly smartGenerator: SmartGenerator,
    private readonly autoCompleteService: AutoCompleteService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(args: string): Promise<IBotCommandResult> {
    const [tasknumber, ...remainder] = args.split(" ");
    const smartCaptureString = remainder.join(" ");
    const taskIndex = parseInt(tasknumber, 10);
    let feedback = "";

    feedback = this.validArguments(taskIndex, smartCaptureString);
    if (!feedback) {
      feedback = await this.updateTask(taskIndex, smartCaptureString);
    }

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // TODO: Bug: UT van een task in staat maybe haalt die uit maybe.
  // Ook:  Nu ik maybe meer ga gebruiken wil ik het direct kunnen zetten bij de invoer. En dus ook bij update task?
  // / voor multicommand. Dus /maybe later /prio:1 etc

  public async updateTask(index: number, stuff: string): Promise<string> {
    // ! Add a transaction when available
    const smartParser = new SmartParser();
    const captureModel = smartParser.parseCaptureText(stuff);
    captureModel.contexts = await this.autoContextService.applyAutoContext(
      captureModel.contexts,
    );

    const existingTask = await this.taskService.getTask(index);

    if (existingTask.completed) {
      return "Error: Can't update a completed task";
    }

    let updatedTask = captureModel.mapToTaskModel();
    updatedTask = ModelCloner.updateValues(updatedTask, {
      uuid: existingTask.uuid,
    });

    const updateFeedback = await this.updateProjects(captureModel);

    await this.taskService.updateTask(updatedTask);
    return updateFeedback + "1 task updated";
  }

  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    const input = AutoCompleteService.addSpaceAfterNumberArgument(commandInput);

    const allTasks = await this.smartGenerator.generateUncompletedTasks();
    const allTasksWithCommand = allTasks.map((t) => "ut " + t);
    const allMatchingTasks = allTasksWithCommand.filter((i) =>
      TextUtils.startsWithIgnoreCase(i, input),
    );
    const canCompleteExistingTask = allMatchingTasks.length > 0;

    if (canCompleteExistingTask) {
      return allMatchingTasks.map((t) => t.slice(3)); // remove "ut "
    } else {
      // Be able to autocomplete further, for example when adding a forgotten project
      return this.autoCompleteService.getEditAutoCompleteKeywords(input);
    }
  }

  private async updateProjects(captureModel: SmartCaptureModel) {
    for (const project of captureModel.projects) {
      const projectModel = new ProjectModel({
        project,
        outcomes: captureModel.outcomes,
      });
      try {
        await this.projectService.saveProject(projectModel);
      } catch (e) {
        return FunctionalError.toStringOrThrow(e as Error) + "\n";
      }
    }
    return "";
  }

  private validArguments(
    taskIndex: number,
    smartCaptureString: string,
  ): string {
    if (!taskIndex) {
      return "Error: No valid task provided. You need to provide a task like this: ut 1 do something";
    }
    if (!smartCaptureString) {
      return "Error: Could not update task with no data";
    }
    return "";
  }
}
