import { ArrayUtils } from "../util/ArrayUtils";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { CompleteProjectFeedbackModel } from "./bot/commands/models/CompleteProjectFeedbackModel";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { ProjectModel } from "./domainModels/ProjectModel";
import { TaskModel } from "./domainModels/TaskModel";
import { FunctionalError } from "./errors/FunctionalError";
import { AddProjectEventModel } from "./events/AddProjectEventModel";
import { CompleteProjectEventModel } from "./events/CompleteProjectEventModel";
import { UpdateProjectEventModel } from "./events/UpdateProjectEventModel";
import { IReadOnlyRepository } from "./IReadonlyRepository";
import { TaskService } from "./TaskService";
import { ProjectViewModel } from "./viewModels/ProjectViewModel";
import { ModelCloner } from "../util/ModelCloner";

// LATER: maybe split in commands and querys?
// viewmodels for the querys and projectmodels for commands?
export class ProjectService {
  constructor(
    private readonly taskService: TaskService,
    private readonly eventBus: DomainEventBus,
    private readonly projectView: IReadOnlyRepository<ProjectViewModel>, // InMemoryRepository
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);

    taskService.injectProjectService(this); // Break circular dependency
  }

  public async getAll(): Promise<readonly ProjectViewModel[]> {
    return this.projectView.getAll();
  }

  public async getNotCompleted(
    context?: string,
  ): Promise<readonly ProjectViewModel[]> {
    const allProjects = await this.getAll();
    const notCompletedProjects = allProjects.filter((p) => !p.completed);
    if (!context) {
      return notCompletedProjects;
    } else {
      const notCompletedProjectsInContext = [];
      for (const project of notCompletedProjects) {
        if (await this.isProjectInContext(project.project, context)) {
          notCompletedProjectsInContext.push(project);
        }
      }
      return notCompletedProjectsInContext;
    }
  }

  public async isProjectInContext(
    project: string,
    context: string,
  ): Promise<boolean> {
    const tasks = await this.taskService.getByProject(project);
    return tasks.some((t) => t.contexts.includes(context));
  }

  public async saveProject(
    projectModel: ProjectModel,
  ): Promise<{ projectsAdded: number; projectsUpdated: number }> {
    if (!projectModel.project) {
      throw new FunctionalError("can't save a project without project text.");
    }

    const existingProject = await this.findProject(projectModel.project);

    if (!existingProject) {
      await this.addProject(projectModel);
      return {
        projectsAdded: 1,
        projectsUpdated: 0,
      };
    } else {
      const uuid = existingProject.uuid;
      const outcomes = ArrayUtils.mergeDistinct([
        projectModel.outcomes,
        existingProject.outcomes,
      ]);

      const updatedProjectModel = ModelCloner.updateValues(projectModel, {
        uuid,
        outcomes,
      });
      await this.updateProject(updatedProjectModel);
      return {
        projectsAdded: 0,
        projectsUpdated: 1,
      };
    }
  }

  public async completeProject(
    index: number,
  ): Promise<CompleteProjectFeedbackModel> {
    const project = await this.getNotCompletedProject(index);

    const eventModel = new CompleteProjectEventModel(project.uuid);
    await this.eventBus.publishEvent(eventModel);

    const projectTasks = await this.completeTasksInProject(project);

    const completeProjectEventModel = new CompleteProjectFeedbackModel(project);
    completeProjectEventModel.tasksCompleted = projectTasks.tasksCompleted;
    completeProjectEventModel.tasksNotCompleted =
      projectTasks.tasksNotCompleted;
    return completeProjectEventModel;
  }

  public async getAutoCompleteProjects(): Promise<string[]> {
    const activeProjects = await this.getNotCompleted();
    const projects = activeProjects.map((p) => p.project);
    return ProjectService.addPlusSign(projects).sort();
  }

  public static addPlusSign(projects: string[]) {
    return projects.map((c) => {
      return "+" + c;
    });
  }

  public async findProject(
    projectName: string,
  ): Promise<ProjectViewModel | undefined> {
    const allProjects = await this.getAll();
    return this.findProjectModelInProjectList(projectName, allProjects);
  }

  public async getUncompletedProjectsWithoutNextActions(
    context?: string,
  ): Promise<readonly ProjectViewModel[]> {
    const projects = await this.getNotCompleted(context);
    const uncompletedProjectsWithoutNextActions: ProjectViewModel[] = [];

    for (const project of projects) {
      const tasks = await this.taskService.getUncompletedTasks(project.project);
      if (tasks.length === 0) {
        uncompletedProjectsWithoutNextActions.push(project);
      }
    }
    return uncompletedProjectsWithoutNextActions;
  }

  public async getUncompletedProjectsWithOnlyMaybe(
    context?: string,
  ): Promise<readonly ProjectViewModel[]> {
    const projects = await this.getNotCompleted(context);

    const projectsWithOnlyMaybeTasks: ProjectViewModel[] = [];

    for (const project of projects) {
      const tasks = await this.taskService.getUncompletedTasks(project.project);
      if (tasks.length > 0 && tasks.every((t) => t.maybe)) {
        projectsWithOnlyMaybeTasks.push(project);
      }
    }
    return projectsWithOnlyMaybeTasks;
  }

  public async getUncompletedProjectsWithNextActions(): Promise<
    readonly ProjectViewModel[]
  > {
    const projects = await this.getNotCompleted();
    const uncompletedProjectsWithNextActions: ProjectViewModel[] = [];

    for (const project of projects) {
      const tasks = await this.taskService.getUncompletedTasks(project.project);
      if (tasks.length > 0) {
        uncompletedProjectsWithNextActions.push(project);
      }
    }
    return uncompletedProjectsWithNextActions;
  }

  public async getProject(projectDisplayId: number): Promise<ProjectViewModel> {
    const allProjects = await this.projectView.getAll();
    const foundProject = allProjects.find((projectModel: ProjectViewModel) => {
      return projectModel.displayId === projectDisplayId;
    });
    if (foundProject === undefined) {
      throw new Error("Can't find project with number " + projectDisplayId);
    }
    return foundProject;
  }

  public async updateProject(projectModel: ProjectModel): Promise<void> {
    const updateProjectEvent = new UpdateProjectEventModel(projectModel);
    await this.eventBus.publishEvent(updateProjectEvent);
  }

  private findProjectModelInProjectList(
    projectName: string,
    projects: readonly ProjectViewModel[],
  ): ProjectViewModel | undefined {
    return projects.find((projectModel: ProjectViewModel) => {
      return projectModel.project === projectName;
    });
  }

  private async completeTasksInProject(project: ProjectModel) {
    const projectTasks = await this.getUncompletedProjectTasks(project);

    for (const task of projectTasks.tasksToComplete) {
      /* istanbul ignore else */ // Should never happen
      if (task.displayId) {
        await this.taskService.completeTask(task.displayId);
      } else {
        throw Error("Failed to complete task " + task.task);
      }
    }
    return {
      tasksCompleted: projectTasks.tasksToComplete,
      tasksNotCompleted: projectTasks.tasksNotToComplete,
    };
  }

  private async getUncompletedProjectTasks(project: ProjectModel) {
    const uncompletedTasks = await this.taskService.getUncompletedTasks(
      project.project,
    );
    const allProjects = await this.getAll();
    const tasksToComplete = uncompletedTasks.filter((task) => {
      return this.areOtherProjectsCompleted(task, project, allProjects);
    });
    const tasksNotToComplete = uncompletedTasks.filter((task) => {
      return !tasksToComplete.includes(task);
    });
    return { tasksToComplete, tasksNotToComplete };
  }

  private areOtherProjectsCompleted(
    task: TaskModel,
    project: ProjectModel,
    allProjects: readonly ProjectViewModel[],
  ): boolean {
    const otherProjectNames = this.getOtherProjectsFromTask(task, project);

    for (const projectName of otherProjectNames) {
      const projectModel = this.findProjectModelInProjectList(
        projectName,
        allProjects,
      );
      if (projectModel !== undefined && !projectModel.completed) {
        return false;
      }
    }

    return true;
  }

  private getOtherProjectsFromTask(
    task: TaskModel,
    project: ProjectModel,
  ): string[] {
    const otherProjectNames = task.projects.slice();
    const indexOfProject = otherProjectNames.indexOf(project.project);
    otherProjectNames.splice(indexOfProject, 1);
    return otherProjectNames;
  }

  private async addProject(projectModel: ProjectModel) {
    const createdProjectModel = ModelCloner.updateValues(projectModel, {
      creationDateTime: DateTimeUtils.getEpoch(),
    });
    const addProjectEvent = new AddProjectEventModel(createdProjectModel);
    await this.eventBus.publishEvent(addProjectEvent);
  }

  private async getNotCompletedProject(
    index: number,
  ): Promise<ProjectViewModel> {
    const projects = await this.getNotCompleted();
    const project = projects.find((proj) => {
      return proj.displayId === index;
    });

    if (project === undefined) {
      throw new FunctionalError("invalid project number");
    }

    return project;
  }
}
