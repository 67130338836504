import { SingleRepository } from "../repository/SingleRepository";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { ContextModel } from "./domainModels/ContextModel";

export class AutoContextService {
  public static formatContext(context: string): string {
    return context.replace("@", "").toLocaleLowerCase();
  }

  private lock: Promise<void> = Promise.resolve();

  constructor(
    private readonly contextRepository: SingleRepository<ContextModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContext(): Promise<string> {
    return (await this.getContextModel()).context;
  }

  public async setContext(context: string): Promise<string> {
    // We need a lock to solve a race condition where multiple code paths try to create the first context model in a singlerepository
    await this.lock;
    // From nodejs 22 replace with   let { promise, resolve, reject } = Promise.withResolvers();
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers#browser_compatibility
    let lockResolve;
    this.lock = new Promise(function (resolve) {
      lockResolve = resolve;
    });
    try {
      const currentContext = await this.getContextModel();
      const formattedContext = AutoContextService.formatContext(context);

      await this.contextRepository.updateValues(currentContext, {
        context: formattedContext,
      });
      return formattedContext;
    } finally {
      lockResolve!();
    }
  }

  public async applyAutoContext(contexts: string[]): Promise<string[]> {
    const currentContext = await this.getContext();
    if (contexts.length === 0 && currentContext) {
      return Promise.resolve([currentContext]);
    }
    return contexts;
  }

  private async getContextModel(): Promise<ContextModel> {
    let currentContext = await this.contextRepository.tryGet();

    if (!currentContext || !currentContext.uuid) {
      currentContext = new ContextModel();
    }
    return currentContext;
  }
}
