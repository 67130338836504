import { IModel } from "../../../domain/domainModels/IModel";
import { Mutable } from "../../../domain/Types";

export function removePreValidationDeprecatedDataFromModels(
  event: Mutable<IModel>,
) {
  if (event.modelVersion > 2) {
    // 2 is the highest modelversion when implemented
    return;
  }

  if (event.ServerSideEncrypted) {
    delete event.ServerSideEncrypted;
  }

  if (event.projectModel?.ServerSideEncrypted) {
    delete event.projectModel.ServerSideEncrypted;
  }

  if (event.taskModel?.ServerSideEncrypted) {
    delete event.taskModel.ServerSideEncrypted;
  }

  if (event.ServerSideEncryptionType) {
    delete event.ServerSideEncryptionType;
  }

  if (event.projectModel?.ServerSideEncryptionType) {
    delete event.projectModel.ServerSideEncryptionType;
  }

  if (event.taskModel?.ServerSideEncryptionType) {
    delete event.taskModel.ServerSideEncryptionType;
  }

  if (event.taskModel?.displayId) {
    delete event.taskModel.displayId;
  }

  if (event.projectModel?.displayId) {
    delete event.projectModel.displayId;
  }
}
