import { IModel } from "../../../domain/domainModels/IModel";
import { Mutable } from "../../../domain/Types";

export function renameUrlsToLinksInTaskModel(event: Mutable<IModel>) {
  if (
    event.taskModel &&
    event.taskModel.modelVersion == 1 &&
    event.taskModel.urls
  ) {
    event.taskModel.links = event.taskModel.urls;
    delete event.taskModel.urls;
    event.taskModel.modelVersion = 2;
  }
}
