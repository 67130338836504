import * as Uuid from "uuid";

import { epoch } from "../Types";
import { IDomainModel } from "./IDomainModel";
import { Validate } from "../validators/Validate";

export class TaskModel implements IDomainModel {
  public readonly modelVersion = 3;
  public readonly __type: string = "TaskModel";

  public readonly uuid: string = Uuid.v4();
  public readonly task: string = "";
  public readonly projects: string[] = [];
  public readonly contexts: string[] = [];
  public readonly completed: boolean = false;
  public readonly completionDateTime?: epoch;
  public readonly creationDateTime?: epoch;
  public readonly maybe: boolean = false;
  public readonly links: string[] = [];
  public readonly tags: string[] = [];

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "task",
    "projects",
    "contexts",
    "completed",
    "completionDateTime",
    "creationDateTime",
    "maybe",
    "links",
    "tags",
  ];

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, TaskModel.expectedProperties);
  }

  constructor(init?: Partial<TaskModel>) {
    Object.assign(this, init);
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.string(this.task);
    Validate.stringArray(this.projects);
    Validate.stringArray(this.contexts);
    Validate.boolean(this.completed);
    Validate.boolean(this.completed);
    Validate.optionalEpoch(this.completionDateTime);
    Validate.optionalEpoch(this.creationDateTime);
    Validate.stringArray(this.links);
    Validate.boolean(this.maybe);
    Validate.stringArray(this.tags);
  }
}
