import { ModelCloner } from "../../util/ModelCloner";
import { TaskModel } from "../domainModels/TaskModel";
import { Validate } from "../validators/Validate";

export class TaskViewModel extends TaskModel {
  // TODO-RAS: Make readonly
  public displayId = 0;

  /* istanbul ignore next */ // TODO: Shouldn't this be used during integration?
  public cloneWithoutViewProperties(): TaskModel {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clone = ModelCloner.clone(this) as any;
    delete clone.displayId;
    return clone;
  }

  protected override validateExpectedProperties() {
    Validate.expectedProperties(
      this,
      TaskModel.expectedProperties.concat(["displayId"]),
    );
  }

  constructor(init?: Partial<TaskViewModel>) {
    super(); // Redundant, but we must call super
    Object.assign(this, init); // TODO-RAS: Test if we can omit this
  }

  // TODO: Consult Niek. Prevent accidental saving of viewmodels? But is kinda breaks inheritance. How to prevent saving displayID and other view properties?
  // TODO: Maybe create a toJSON() method ? Or can we do something in the constructor of events to tranfer view models to normal models/
  // Prevent accidental saving of viewmodels
  // public override validate(intended = false) {
  //   if (!intended) {
  //     throw new TechnicalError(
  //       "Unintended validation of a viewmodel. Is it being saved?",
  //     );
  //   }
  //   super.validate();
  // }
}
