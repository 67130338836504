import { DateTimeUtils } from "../../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../../util/DependencyInjectionUtils";
import { ModelCloner } from "../../util/ModelCloner";
import { AutoContextService } from "../AutoContextService";
import { ProjectModel } from "../domainModels/ProjectModel";
import { FunctionalError } from "../errors/FunctionalError";
import { ProjectService } from "../ProjectService";
import { TaskService } from "../TaskService";
import { SmartCaptureModel } from "./SmartCaptureModel";
import { SmartCaptureWebParser } from "./SmartCaptureWebParser";
import { SmartParser } from "./SmartParser";

export class SmartCaptureService {
  private readonly smartParser: SmartParser;

  constructor(
    private readonly autoContextService: AutoContextService,
    private readonly projectService: ProjectService,
    private readonly smartCaptureWebParser: SmartCaptureWebParser,
    private readonly taskService: TaskService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
    this.smartParser = new SmartParser();
  }

  public async capture(input: string): Promise<string> {
    const model = this.smartParser.parseCaptureText(input);

    model.contexts = await this.autoContextService.applyAutoContext(
      model.contexts,
    );

    await this.smartCaptureWebParser.applyAutoTask(model);

    const feedbackTask = await this.addAndSaveTask(model);
    const feedbackProject = await this.saveProjects(model);
    return `${feedbackTask} \n ${feedbackProject}`;
  }

  private async addAndSaveTask(
    parsedModel: SmartCaptureModel,
  ): Promise<string> {
    if (parsedModel.task) {
      let taskModel = parsedModel.mapToTaskModel();
      taskModel = ModelCloner.updateValues(taskModel, {
        creationDateTime: DateTimeUtils.getEpoch(),
      });
      await this.taskService.add(taskModel);
      return "1 task added";
    }
    return "no task added";
  }

  private async saveProjects(parsedModel: SmartCaptureModel): Promise<string> {
    let numberOfProjectsAdded = 0;
    let feedback = "";
    for (const project of parsedModel.projects) {
      const projectModel = new ProjectModel({
        project,
        outcomes: parsedModel.outcomes,
      });

      try {
        const { projectsAdded } =
          await this.projectService.saveProject(projectModel);
        numberOfProjectsAdded += projectsAdded;
      } catch (e) {
        feedback += FunctionalError.toStringOrThrow(e as Error) + "\n";
      }
    }
    return (
      feedback +
      `${numberOfProjectsAdded} project${
        numberOfProjectsAdded !== 1 ? "s" : ""
      } added`
    );
  }
}
