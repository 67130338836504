import { Component, ReactNode } from "react";

import "./StatusBar.css";

import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { ProjectService } from "brainsupporter-core/lib/domain/ProjectService";
import { AutoContextService } from "brainsupporter-core/lib/domain/AutoContextService";
import { ProjectViewModel } from "brainsupporter-core/lib/domain/viewModels/ProjectViewModel";
import { FormattingService } from "brainsupporter-core/lib/domain/bot/FormattingService";
import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";

type StatusBarProps = {
  root: AbstractCompositionRoot;
};

class StatusBarState {
  projectsWithoutUncompletedTasks: readonly ProjectViewModel[] = [];
  projectsWithOnlyMaybeTasks: readonly ProjectViewModel[] = [];
}

class StatusBar extends Component<StatusBarProps, StatusBarState> {
  private static UNSUBSCRIBE_GROUP = "StatusBar";

  root: AbstractCompositionRoot;
  projectService: ProjectService;
  autoContextService: AutoContextService;
  formattingService: FormattingService;
  notifier: Notifier;

  constructor(props: StatusBarProps) {
    super(props);

    this.root = this.props.root;
    this.projectService = this.props.root.ProjectService;
    this.autoContextService = this.props.root.AutoContextService;
    this.formattingService = this.props.root.FormattingService;
    this.notifier = this.props.root.Notifier;

    this.state = new StatusBarState();
  }

  override async componentDidMount() {
    this.notifier.subscribe(
      Notifier.UPDATE_UI,
      /* istanbul ignore next */ // Coverage can be improved
      async () => {
        void this.updateStatus();
      },
      StatusBar.UNSUBSCRIBE_GROUP,
    );

    void this.updateStatus();
  }

  override async componentWillUnmount() {
    this.notifier.unsubscribeGroup(StatusBar.UNSUBSCRIBE_GROUP);
  }

  async updateStatus() {
    const context = await this.autoContextService.getContext();

    const projectsWithoutUncompletedTasks =
      await this.projectService.getUncompletedProjectsWithoutNextActions(
        context,
      );

    const projectsWithOnlyMaybeTasks =
      await this.projectService.getUncompletedProjectsWithOnlyMaybe(context);

    this.setState({
      projectsWithoutUncompletedTasks: projectsWithoutUncompletedTasks,
      projectsWithOnlyMaybeTasks: projectsWithOnlyMaybeTasks,
    });
  }

  override render(): ReactNode {
    // TODO: The tooltips css was generated by chatGPT and should be accesible.
    // It works but doesn't scale to the content and can fall of the edge. Improve this
    return (
      <span className="StatusBar">
        {this.state.projectsWithoutUncompletedTasks.length > 0 && (
          <div className="tooltip">
            <span aria-describedby="projects-without-uncompleted-tasks-tooltip">
              😐
            </span>
            <span
              id="projects-without-uncompleted-tasks-tooltip"
              role="tooltip"
              className="tooltip-text"
            >
              <div>Projects without tasks:</div>
              {this.state.projectsWithoutUncompletedTasks.map((project) => {
                return (
                  <div key={project.uuid}>
                    {this.formattingService.formatProject(project)}
                  </div>
                );
              })}
            </span>
          </div>
        )}
        {this.state.projectsWithOnlyMaybeTasks.length > 0 && (
          <div className="tooltip">
            <span aria-describedby="projects-with-only-maybe-tasks-tooltip">
              😴
            </span>
            <span
              id="projects-with-only-maybe-tasks-tooltip"
              role="tooltip"
              className="tooltip-text"
            >
              <div>Projects with all remaining tasks marked someday/maybe:</div>
              {this.state.projectsWithOnlyMaybeTasks.map((project) => {
                return (
                  <div key={project.uuid}>
                    {this.formattingService.formatProject(project)}
                  </div>
                );
              })}
            </span>
          </div>
        )}
      </span>
    );
  }
}

export default StatusBar;
