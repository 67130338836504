import * as TextEncoding from "text-encoding";
import { Validate } from "../domain/validators/Validate";

export class TextUtils {
  // Not currently used, but don't want to delete this
  /* istanbul ignore next */
  public static extractSingleTextFragmentWithoutItsToken(
    item: string,
    startToken: RegExp,
    allTokens: RegExp,
  ): string {
    const result = this.extractMultipleTextFragmentsWithoutTheirTokens(
      item,
      startToken,
      allTokens,
    );
    if (result.length > 0) {
      return result[0];
    } else {
      return "";
    }
  }

  public static extractTextFragmentUntilAnyToken(
    item: string,
    allTokens: RegExp,
    includeStartToken = false,
  ): string {
    let firstTokenPosition = this.getFirstTokenPosition(item, allTokens);

    if (includeStartToken && firstTokenPosition === 0) {
      firstTokenPosition = this.getFirstTokenPosition(
        item.substring(1), // cut one char so we move to the second token
        allTokens,
      );

      if (firstTokenPosition != -1) {
        // If we found a second token, add one extra position for the one we skipped
        firstTokenPosition++;
      }
    }

    if (firstTokenPosition > -1) {
      return item.substring(0, firstTokenPosition).trim();
    } else {
      return item.trim();
    }
  }

  public static extractMultipleTextFragmentsIncludingTheirTokens(
    item: string,
    startToken: RegExp,
    allTokens: RegExp,
  ): string[] {
    return this.extractMultipleTextFragments(item, startToken, allTokens, true);
  }

  public static extractMultipleTextFragmentsWithoutTheirTokens(
    item: string,
    startToken: RegExp,
    allTokens: RegExp,
  ): string[] {
    return this.extractMultipleTextFragments(
      item,
      startToken,
      allTokens,
      false,
    );
  }

  public static stringToBytes(text: string, forcePolyfill = false): Uint8Array {
    /* istanbul ignore next */ // Can't cover both in integration test
    if (typeof TextEncoder === "undefined" || forcePolyfill) {
      // Use polyfill when not available
      return new TextEncoding.TextEncoder().encode(text);
    } else {
      return new TextEncoder().encode(text);
    }
  }

  public static bytesToString(
    bytes: Uint8Array,
    forcePolyfill = false,
  ): string {
    /* istanbul ignore next */ // Can't cover both in integration test
    if (typeof TextDecoder === "undefined" || forcePolyfill) {
      // Use polyfill when not available
      return new TextEncoding.TextDecoder().decode(bytes);
    } else {
      return new TextDecoder().decode(bytes);
    }
  }

  public static startsWithIgnoreCase(text: string, search: string): boolean {
    const textLow = text.toLocaleLowerCase();
    const searchLow = search.toLocaleLowerCase();
    return textLow.startsWith(searchLow);
  }

  public static endsWithIgnoreCase(text: string, search: string): boolean {
    const textLow = text.toLocaleLowerCase();
    const searchLow = search.toLocaleLowerCase();
    return textLow.endsWith(searchLow);
  }

  public static compareIgnoreCase(text1: string, text2: string): boolean {
    const text1Low = text1.toLocaleLowerCase();
    const text2Low = text2.toLocaleLowerCase();
    return text1Low === text2Low;
  }

  public static endsWithNumber(text: string) {
    /* istanbul ignore else */ // Would be obscure int. test
    if (text) {
      const lastChar = text.slice(-1);
      return Validate.isNumber(lastChar);
    } else {
      return false;
    }
  }

  public static newLinesToSpaces(text: string) {
    return text.replace("\r\n", " ").replace("\r", " ").replace("\n", " ");
  }

  private static extractMultipleTextFragments(
    text: string,
    startToken: RegExp,
    allTokens: RegExp,
    includeStartToken: boolean,
  ): string[] {
    const tokenPosition = this.getFirstTokenPosition(text, startToken);
    if (tokenPosition === -1) {
      return [];
    }
    const tokenString = this.stringMatch(text, startToken);
    const tokenLength = tokenString.length;
    const beginPositionNextResult = includeStartToken
      ? tokenPosition
      : tokenPosition + tokenLength;
    const remainingTextThisResult = text.substring(beginPositionNextResult);
    const thisResult = this.extractTextFragmentUntilAnyToken(
      remainingTextThisResult,
      allTokens,
      includeStartToken,
    );
    const nextText = text.substring(
      beginPositionNextResult + thisResult.length,
    );
    const results = [thisResult];
    const recursiveResults = this.extractMultipleTextFragments(
      nextText,
      startToken,
      allTokens,
      includeStartToken,
    );
    return results.concat(recursiveResults);
  }

  private static getFirstTokenPosition(
    item: string,
    allTokens: RegExp,
  ): number {
    return item.search(allTokens);
  }

  private static stringMatch(input: string, token: RegExp): string {
    const tokenMatch = input.match(token); // Defensive code which cannot be executed currently
    /* istanbul ignore else */ if (tokenMatch && tokenMatch.length > 0) {
      return tokenMatch[0];
    } else {
      return "";
    }
  }
}
