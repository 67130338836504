import { ArrayUtils } from "../../util/ArrayUtils";
import { DomainEventBus } from "../pubsub/DomainEventBus";
import { IDomainModel } from "../domainModels/IDomainModel";
import { ProjectModel } from "../domainModels/ProjectModel";
import { AddProjectEventModel } from "../events/AddProjectEventModel";
import { CompleteProjectEventModel } from "../events/CompleteProjectEventModel";
import { UpdateProjectEventModel } from "../events/UpdateProjectEventModel";
import { InMemoryRepository } from "../../repository/InMemoryRepository";
import { ProjectService } from "../ProjectService";
import { ProjectViewModel } from "../viewModels/ProjectViewModel";
import { ModelCloner } from "../../util/ModelCloner";

export class ProjectViewGenerator {
  constructor(
    private readonly projectView: InMemoryRepository<ProjectViewModel>,
    private readonly eventBus: DomainEventBus,
    private readonly projectService: ProjectService,
  ) {
    this.eventBus.subscribe(
      AddProjectEventModel.eventNameConstant,
      async (event) =>
        await this.addProjectEventSubscriber(event as AddProjectEventModel),
    );
    this.eventBus.subscribe(
      UpdateProjectEventModel.eventNameConstant,
      async (event) =>
        await this.updateProjectEventSubscriber(
          event as UpdateProjectEventModel,
        ),
    );
    this.eventBus.subscribe(
      CompleteProjectEventModel.eventNameConstant,
      async (event) =>
        await this.completeProjectEventSubscriber(
          event as CompleteProjectEventModel,
        ),
    );
  }

  private async addProjectEventSubscriber(event: IDomainModel) {
    const projectModel = (event as AddProjectEventModel).projectModel;

    // TODO: Should this project logic be here? Move it to the project service?
    const viewModel = await this.createNewViewModel(projectModel);

    const existingProject = await this.projectService.findProject(
      projectModel.project,
    ); // Only happens during conflict resolution. Consider writing a test when live.
    /* istanbul ignore else */
    if (!existingProject) {
      await this.projectView.save(viewModel);
    } else {
      const outcomes = ArrayUtils.mergeDistinct([
        projectModel.outcomes,
        existingProject.outcomes,
      ]);
      const updatedProjectModel = ModelCloner.updateValues(projectModel, {
        outcomes,
      });
      await this.updateProject(updatedProjectModel, existingProject);
    }
  }

  private async createNewViewModel(
    projectModel: ProjectModel,
  ): Promise<ProjectViewModel> {
    const allProjects = await this.projectView.getAll();
    const numberOfProjects = allProjects.length;
    const projectViewModel = new ProjectViewModel();
    Object.assign(projectViewModel, projectModel);
    projectViewModel.displayId = numberOfProjects + 1;
    return projectViewModel;
  }

  private async updateProjectEventSubscriber(event: IDomainModel) {
    const updatedProjectModel = (event as UpdateProjectEventModel).projectModel;
    const existingProject = await this.projectView.getByUuid(
      updatedProjectModel.uuid,
    );
    await this.updateProject(updatedProjectModel, existingProject);
  }

  private async updateProject(
    updatedProjectModel: ProjectModel,
    existingProject: ProjectViewModel,
  ) {
    // TODO: Should this project logic be here? Move it to the project service?
    const updatedProjectModelVM = existingProject.cloneUpdatedValues(
      updatedProjectModel,
    ) as ProjectViewModel;
    await this.projectView.save(updatedProjectModelVM);
  }

  private async completeProjectEventSubscriber(
    event: CompleteProjectEventModel,
  ) {
    const projectViewModel = await this.projectView.getByUuid(
      event.projectUuid,
    );

    await this.projectView.updateValues(projectViewModel, {
      completed: true,
      completionDateTime: event.eventDateTime,
    });
  }
}
