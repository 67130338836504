import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventModel } from "./events/DomainEventModel";
import { EventStore } from "./EventStore";
import { IRepository } from "./IRepository";
import { DomainEventBus } from "./pubsub/DomainEventBus";
import { Notifier } from "./pubsub/Notifier";
import { ProjectViewModel } from "./viewModels/ProjectViewModel";
import { TaskViewModel } from "./viewModels/TaskViewModel";

export class SynchronisationService {
  constructor(
    private readonly notifier: Notifier,
    private readonly taskView: IRepository<TaskViewModel>,
    private readonly projectView: IRepository<ProjectViewModel>,
    private readonly eventBus: DomainEventBus,
    private readonly eventStore: EventStore,
    private readonly eventRepo: IRepository<DomainEventModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async reloadEvents(): Promise<void> {
    await this.notifier.publish(Notifier.SYNCING, true);
    // Get events before deleting the view to minimize time where there is no correct state in the view
    const events = await this.eventRepo.getAll();
    await this.taskView.deleteAll();
    await this.projectView.deleteAll();
    this.eventBus.reset();
    await this.eventStore.replayEvents(events);
    await this.notifier.publish(Notifier.SYNCING, false);
    await this.notifier.publish(Notifier.UPDATE_UI);
  }
}
