import { TechnicalError } from "../domain/errors/TechnicalError";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { IConfig } from "./IConfig";

export class ConfigurationManager {
  public testUuid: string | undefined = "";

  private email: string = "";
  private accountFolder: string = "";

  constructor(private readonly config: IConfig) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  // env variable is always set
  /* istanbul ignore next */ // Not used during integration tests
  public baseUrlApi(): string {
    return this.config.baseUrlApi;
  }

  /* istanbul ignore next */
  public azureAccessKey(): string {
    // Envirmonent is nodejs specific and does not work in the browser
    return process.env.AZURE_ACCESS_KEY || "";
  }

  // env variable is always set
  /* istanbul ignore next */
  public azureStorageAccount(): string {
    return process.env.AZURE_STORAGE_ACCOUNT || "";
  }

  public storeInAzure(): boolean {
    /* istanbul ignore next */ // STORE_IN_AZURE always has a value in integration tests
    return process.env.STORE_IN_AZURE
      ? process.env.STORE_IN_AZURE === "true"
      : this.config.storeInAzure;
  }

  /* istanbul ignore next */
  public azureShareName(): string {
    return process.env.AZURE_SHARE_NAME || "";
  }

  public dataFolder(): string {
    const accountFolder = this.getAccountFolder();
    const rootDataFolder = this.getRootDataFolder();

    /* istanbul ignore next */ // can't test when testUuid is empty
    const folder = rootDataFolder + `/${accountFolder}`;
    /* istanbul ignore next */ // else not used during integration test
    return this.testUuid ? folder + `/${this.testUuid}` : folder;
  }

  public domainEventsFolder(): string {
    return this.dataSubFolder("domainEvents");
  }

  public exportsFolder(): string {
    return this.dataSubFolder("exports");
  }

  /* istanbul ignore next */ // Only used from web
  public setEmail(email: string) {
    this.email = email;
  }

  public getEmail(): string {
    /* istanbul ignore if */ // not used during integration test
    if (!this.email) {
      throw new TechnicalError("Email is not initialized");
    }

    return this.email;
  }

  public setAccountFolder(accountFolder: string): void {
    this.accountFolder = accountFolder;
  }

  public tryGetAccountFolder(): string {
    return this.accountFolder;
  }

  public getAccountFolder(): string {
    /* istanbul ignore if */ // not used during integration test
    if (!this.accountFolder) {
      throw new TechnicalError("AccountFolder is not initialized");
    }

    return this.accountFolder;
  }

  public logFolder(): string {
    return this.dataSubFolder("log");
  }

  public todoTxtFile(): string {
    return this.dataFolder() + "/todo.txt";
  }

  public taskFolder(): string {
    return this.dataSubFolder("tasks");
  }

  public projectFolder(): string {
    return this.dataSubFolder("projects");
  }

  public projectFile(): string {
    return this.dataFolder() + "/projects.txt";
  }

  public contextFolder(): string {
    return this.dataSubFolder("contexts");
  }

  public userFolder(): string {
    return this.dataSubFolder("users");
  }

  /* istanbul ignore next */ // not used during integration test
  public sessionTokenFolder(): string {
    return this.dataSubFolder("sessionTokens");
  }

  /* istanbul ignore next */ // Used during debugging without encryption.
  public enableEncryption(): boolean {
    return !!process.env.ENABLE_ENCRYPTION || this.config.enableEncryption;
  }

  public disabledCommands(): string[] {
    return this.config.disabledCommands.map((dc) => dc.toLowerCase());
  }

  private dataSubFolder(subfolder: string) {
    return this.dataFolder() + "/" + subfolder;
  }

  /* istanbul ignore next */ // Not using STORE_IN_AZURE during testing.
  private getRootDataFolder() {
    return this.storeInAzure() ? "data" : this.config.localDataFolder;
  }
}
