import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { FunctionalError } from "../../errors/FunctionalError";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";
import { Options } from "open";

export class OpenLinksBotQuery implements IExecutableBotCommand {
  public static staticCommandName = "ol";
  public readonly commandName = OpenLinksBotQuery.staticCommandName;
  public readonly description = "Open a tasks urls";
  public readonly smartButtonText = "Open link";
  public readonly argumentDescription = "tasknumber";

  constructor(
    private readonly taskService: TaskService,
    private readonly open: (
      target: string,
      options?: Options | undefined,
    ) => Promise<void>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(taskNumberString: string): Promise<IBotCommandResult> {
    const taskIndex = parseInt(taskNumberString, 10);
    const feedback = await this.openTaskLinks(taskIndex);
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  public async openTaskLinks(index: number): Promise<string> {
    try {
      let feedback = "";
      const task = await this.taskService.getTask(index);

      if (task.links.length === 0) {
        feedback = "This task has no links";
      }

      for (const url of task.links) {
        feedback += url + "\n";
        await this.open(url, { wait: false });
      }

      return feedback;
    } catch (e) {
      return FunctionalError.toStringOrThrow(e as Error);
    }
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
