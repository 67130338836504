import { IEncryptedModel } from "../domain/domainModels/IEncryptedModel";
import { IModel } from "../domain/domainModels/IModel";
import { EncryptionService } from "../domain/EncryptionService";
import { Mutable } from "../domain/Types";
import { ModelCloner } from "./ModelCloner";

export class ModelEncrypter {
  public static encryptModel(
    model: IModel,
    encryptionKey: Uint8Array,
  ): IEncryptedModel {
    const properties = Object.getOwnPropertyNames(model);

    const clonedModel = ModelCloner.clone(model) as Mutable<IEncryptedModel>;

    for (const property of properties) {
      if (ModelEncrypter.shouldNotEncryptProperty(property)) {
        clonedModel[property] = model[property];
      } else {
        const value = model[property];

        let jsonValue: string; // We have no undefined values in integration test currently
        /* istanbul ignore if */
        if (value === undefined) {
          jsonValue = JSON.stringify(ModelEncrypter.undefinedEscapeString);
        } else if (ModelCloner.isModel(value)) {
          jsonValue = JSON.stringify(
            ModelEncrypter.encryptModel(value, encryptionKey),
          );
        } else {
          jsonValue = JSON.stringify(value);
          /* istanbul ignore if */ // not used in integration test
          if (jsonValue.includes(ModelEncrypter.undefinedEscapeString)) {
            throw new Error("Detected undefinedEscapeString in userdata");
          }
        }

        const encryptedValue = EncryptionService.encrypt(
          jsonValue,
          encryptionKey,
        );
        clonedModel[property] = encryptedValue;
      }
    }
    return clonedModel;
  }

  public static decryptModel(
    encryptedModel: IEncryptedModel,
    encryptionKey: Uint8Array,
  ): IModel {
    const decryptedModel = new Object() as Mutable<IModel>; // Cannot test during integrations // Cannot test during integrations

    const properties = Object.getOwnPropertyNames(encryptedModel);

    for (const property of properties) {
      if (ModelEncrypter.shouldNotEncryptProperty(property)) {
        decryptedModel[property] = encryptedModel[property];
      } else {
        const encryptedValue = encryptedModel[property];
        const decryptedValue = EncryptionService.decrypt(
          encryptedValue,
          encryptionKey,
        );

        let parsedDecryptedValue = JSON.parse(decryptedValue);

        /* istanbul ignore if */
        if (parsedDecryptedValue === ModelEncrypter.undefinedEscapeString) {
          parsedDecryptedValue = undefined;
        } else if (ModelCloner.isModel(parsedDecryptedValue)) {
          parsedDecryptedValue = this.decryptModel(
            parsedDecryptedValue,
            encryptionKey,
          );
        }

        decryptedModel[property] = parsedDecryptedValue;
      }
    }

    /* istanbul ignore else */ // Not used during integration
    if (ModelCloner.HasProp(decryptedModel, "__type")) {
      return ModelCloner.cloneToType(decryptedModel);
    } else {
      return decryptedModel;
    }
  }

  private static readonly undefinedEscapeString =
    "undefined-4af12138-cb77-4656-9ddb-b90ddf671c21-undefined";

  private static shouldNotEncryptProperty(property: string) {
    return (
      property === "uuid" ||
      // These two below are in here for backwards compatibility.
      property === "ServerSideEncrypted" ||
      property === "ServerSideEncryptionType"
    );
  }
}
