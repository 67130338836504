import { TechnicalError } from "../errors/TechnicalError";

// TODO: The last part () => Promise<void> seems ignored requiring the any calling sub below. Can this be fixed?
type notifySubscriber<T> =
  | ((param: T) => Promise<void>)
  | (() => Promise<void>);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Notifier<T = any> {
  public static readonly UPDATE_UI = "Update_UI"; // TODO: Move all constants here
  public static readonly SYNCING = "Syncing";
  public static readonly VISIBILITY_CHANGED = "Visibility_Changed";

  protected wildcardSubscribers: notifySubscriber<T>[] = [];
  protected subscribers: {
    [topic: string]: {
      notifySubscriber: notifySubscriber<T>;
      unsubscribeGroup: string;
    }[];
  } = {};

  public async publish(topic: string, param?: T): Promise<void> {
    // wildcardSubscribers so we do the validation before processing others
    for (const sub of this.wildcardSubscribers) {
      /* istanbul ignore if */ // Without params only used via web so not in integration currently
      if (param === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (sub as any)();
      } else {
        await sub(param);
      }
    }

    if (this.subscribers[topic]) {
      for (const sub of this.subscribers[topic]) {
        /* istanbul ignore if */ // Without params only used via web so not in integration currently
        if (param === undefined) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await (sub.notifySubscriber as any)();
        } else {
          await sub.notifySubscriber(param);
        }
      }
    }
  }

  public subscribe(
    topic: string,
    sub: notifySubscriber<T>,
    unsubscribeGroup: string = "",
  ): notifySubscriber<T> {
    if (!this.subscribers[topic]) {
      this.subscribers[topic] = [];
    }

    this.subscribers[topic].push({ notifySubscriber: sub, unsubscribeGroup });
    return sub;
  }

  /* istanbul ignore next */ // Not yet used in integration
  public unsubscribeGroup(unsubscribeGroup: string): void {
    const unsubscribes = [];

    for (const topic of Object.keys(this.subscribers)) {
      for (const subscriber of this.subscribers[topic]) {
        if (subscriber.unsubscribeGroup === unsubscribeGroup) {
          unsubscribes.push(() =>
            this.unsubscribe(topic, subscriber.notifySubscriber),
          );
        }
      }
    }

    // Can't alter the array in the same loop
    for (const unsubscribe of unsubscribes) {
      unsubscribe();
    }
  }

  /* istanbul ignore next */ // Not yet used in integration
  public unsubscribe(
    topic: string,
    notifySubscriber: notifySubscriber<T>,
  ): void {
    if (!this.subscribers[topic]) {
      throw new TechnicalError("Subscriber array not initialized");
    }

    const index = this.subscribers[topic].findIndex(
      (sub) => sub.notifySubscriber === notifySubscriber,
    );
    if (index > -1) {
      this.subscribers[topic].splice(index, 1);
    } else {
      throw new TechnicalError("Subscriber not found");
    }
  }

  public wildcardSubscribe(sub: notifySubscriber<T>): void {
    this.wildcardSubscribers.push(sub);
  }

  /* istanbul ignore next */ // No longer used currently
  public wildcardRemoveAllSubscribers(): void {
    this.wildcardSubscribers = [];
  }
}
