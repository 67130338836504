import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { EnvironmentUtils } from "../../../util/EnvironmentUtils";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class VersionBotQuery implements IExecutableBotCommand {
  public readonly commandName = "version";
  public readonly description = "Prints the version";
  public readonly smartButtonText = "Version";

  constructor() {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(): Promise<IBotCommandResult> {
    const feedback = EnvironmentUtils.getFullVersion();
    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }
}
