import { Component, SyntheticEvent } from "react";
import "./ErrorNotification.css";

type ErrorNotificationProps = {
  errorMessages: string[];
  onChange: (errorMessages: string[]) => void;
};

/* istanbul ignore next */ // Coverage can be improved
class ErrorNotification extends Component<ErrorNotificationProps, unknown> {
  constructor(props: ErrorNotificationProps) {
    super(props);
  }

  private closeError(e: SyntheticEvent, index: number) {
    e.preventDefault();
    const newErrorMessages = this.removeErrorMessage(index);
    this.props.onChange(newErrorMessages);
  }

  private removeErrorMessage(index: number): string[] {
    const newErrorMessages = this.props.errorMessages.slice();
    newErrorMessages.splice(index, 1);
    return newErrorMessages;
  }

  override render() {
    const errorTemplate = this.props.errorMessages.map(
      (errorMessage, index) => {
        return (
          <div className="error" key={index}>
            <button
              className="close"
              onClick={(e) => this.closeError(e, index)}
            />
            <div className="errorMessage">{errorMessage}</div>
          </div>
        );
      },
    );

    return <div className="errorNotification">{errorTemplate}</div>;
  }
}

export default ErrorNotification;
