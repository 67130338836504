import { EncryptionService } from "brainsupporter-core/lib/domain/EncryptionService";
import { BaseSecureCache } from "brainsupporter-core/lib/util/BaseSecureCache";

/* istanbul ignore next */ // Coverage can be improved
export class LocalStorageSecureCache extends BaseSecureCache {
  private secureCachePrefix = "bs-secure-cache.";

  /* istanbul ignore next */ // Only used in unit tests
  public clearCache(): void {
    const allLocalStorageItems = Object.values(window.localStorage);
    for (const cacheKey of Object.keys(allLocalStorageItems)) {
      if (cacheKey.startsWith(this.secureCachePrefix)) {
        window.localStorage.removeItem(cacheKey);
      }
    }
    this.encryptionKey = undefined;
  }

  protected async initialize(): Promise<void> {
    // first get from localstorage
    const encryptionKeyName = "cacheEncryptionKey";
    if (!this.encryptionKey) {
      const encryptionKey = await this.getItem(encryptionKeyName);
      if (encryptionKey) {
        this.encryptionKey = new Uint8Array(JSON.parse(encryptionKey));
      }
    }

    // if not present in localstorage, create a new key and store in localstorage
    if (!this.encryptionKey) {
      this.encryptionKey = EncryptionService.createRandomBytes(16);
      const encryptionKeyString = JSON.stringify(
        Array.from(this.encryptionKey),
      );
      this.setItem(
        this.secureCachePrefix + encryptionKeyName,
        encryptionKeyString,
      );
    }
  }

  protected async putCacheAndResolve(
    cacheKey: string,
    resolveFn: () => Promise<Uint8Array>,
  ): Promise<void> {
    await this.initialize();

    this.setItem(
      this.secureCachePrefix + cacheKey,
      await resolveFn().then((value) =>
        EncryptionService.encryptBytes(value, this.encryptionKey as Uint8Array),
      ),
    );
  }

  protected async getItem(cacheKey: string): Promise<string | null> {
    return Promise.resolve(
      window.localStorage.getItem(this.secureCachePrefix + cacheKey),
    );
  }

  protected setItem(cacheKey: string, item: string): void {
    window.localStorage.setItem(cacheKey, item);
  }

  public cacheKeyExists(cacheKey: string): boolean {
    const completeCacheKey = this.secureCachePrefix + cacheKey;
    return !!window.localStorage.getItem(completeCacheKey);
  }
}
