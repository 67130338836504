import * as Bcrypt from "bcryptjs";
import * as Cheerio from "cheerio";

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { ConfigurationManager } from "brainsupporter-core/lib/config/ConfigurationManager";
import { AppendOnlyRepository } from "brainsupporter-core/lib/repository/AppendOnlyRepository";
import { CachedRepository } from "brainsupporter-core/lib/repository/CachedRepository";
import { ResilientRepository } from "brainsupporter-core/lib/repository/ResilientRepository";
import { EncryptedRepository } from "brainsupporter-core/lib/repository/EncryptedRepository";
import { ValidatedRepository } from "brainsupporter-core/lib/repository/ValidatedRepository";
import { EncryptionService } from "brainsupporter-core/lib/domain/EncryptionService";
import { FunctionalError } from "brainsupporter-core/lib/domain/errors/FunctionalError";
import { ApiRepository } from "brainsupporter-core/lib/repository/ApiRepository";
import { PagedApiRepository } from "brainsupporter-core/lib/repository/PagedApiRepository";
import { MigratedValidatedRepository } from "brainsupporter-core/lib/repository/MigratedValidatedRepository";
import { DomainEventBus } from "brainsupporter-core/lib/domain/pubsub/DomainEventBus";
import { EventStore } from "brainsupporter-core/lib/domain/EventStore";
import { RestUtils } from "brainsupporter-core/lib/util/RestUtils";
import { UserModel } from "brainsupporter-core/lib/domain/domainModels/UserModel";
import { IEncryptedModel } from "brainsupporter-core/lib/domain/domainModels/IEncryptedModel";
import { SmartCaptureWebParser } from "brainsupporter-core/lib/domain/SmartCapture/SmartCaptureWebParser";
import { EncryptionPasswordService } from "brainsupporter-core/lib/domain/EncryptionPasswordService";
import { InMemorySecureCache } from "brainsupporter-core/lib/util/InMemorySecureCache";
import { UserModelMigrator } from "brainsupporter-core/lib/migrations/user/UserModelMigrator";
import { EventMigrator } from "brainsupporter-core/lib/migrations/events/EventMigrator";
import { ContextModel } from "brainsupporter-core/lib/domain/domainModels/ContextModel";
import { SingleRepository } from "brainsupporter-core/lib/repository/SingleRepository";

import { WebConfig } from "./WebConfig";
import { BrowserWebParser } from "./BrowserWebParser";
import { LocalStorageSecureCache } from "./util/LocalStorageSecureCache";
import { ApplicationInsightsLogService } from "./applicationInsights/ApplicationInsightsLogService";
import { IRepository } from "brainsupporter-core/lib/domain/IRepository";
import { DomainEventModel } from "brainsupporter-core/lib/domain/events/DomainEventModel";
import { IndexedDbRepository } from "./repository/IndexedDbRepository";

/* istanbul ignore file */ // Test use TestCompositionRoot

export class WebCompositionRoot extends AbstractCompositionRoot {
  public DomainEventRepositoryCache!: IRepository<DomainEventModel>;

  protected static override staticallyInitialized = false;

  protected override setConfigurableDependencies() {
    this.BcryptHash = Bcrypt.hash;
    this.BcryptGenSalt = Bcrypt.genSalt;
    this.Cheerio = Cheerio.default;
    this.Open = (url: string) => {
      const win = window.open(url, "_blank");

      if (!win || win.closed || typeof win.closed == "undefined") {
        throw new FunctionalError(
          "cannot open multiple tabs because of the popup blocker in the browser. Allow popups for this page for this to work.",
        );
      }
    };
    this.FetchFn = (input: RequestInfo, init?: RequestInit) => {
      return window.fetch(input, init);
    };
    this.RestUtils = new RestUtils(this.FetchFn);

    this.ConfigurationManager = new ConfigurationManager(new WebConfig());

    this.LogService = new ApplicationInsightsLogService(
      this.ConfigurationManager,
    );

    this.EncryptionKeySecureCache = new InMemorySecureCache();
    this.EncryptionService = new EncryptionService(
      this.BcryptHash,
      this.EncryptionKeySecureCache,
    );

    const retryInterval = [10, 100, 1000, 5000, 20000, 60000, 300000]; // TODO-BUFFER: remove some retries when we implemented a proper buffer

    this.UserRepository = new SingleRepository(
      new MigratedValidatedRepository(
        new ResilientRepository(
          new ApiRepository<UserModel>(
            this.LogService,
            this.RestUtils,
            this.ConfigurationManager,
            "api/users",
            "UserModel",
          ),
          this.LogService,
          retryInterval,
        ),
        new UserModelMigrator(),
      ),
    );

    this.EncryptedDomainEventRepository =
      new PagedApiRepository<IEncryptedModel>(
        this.LogService,
        this.RestUtils,
        this.ConfigurationManager,
        "api/events",
        "IEncryptedModel", // TODO: This wil not work when we ever get multiple encrypted repos. Should be something with events?
      );

    this.EncryptionPasswordService = new EncryptionPasswordService(
      this.LogService,
      this.ConfigurationManager,
      this.EncryptionService,
      this.UserRepository,
      this.BcryptGenSalt,
      new LocalStorageSecureCache(),
      this.EncryptionKeySecureCache,
    );

    // Repository to facilitate en/decrypting, caching and retries
    this.DomainEventRepository = new AppendOnlyRepository(
      new CachedRepository(
        this.LogService,
        new IndexedDbRepository<DomainEventModel>("DomainEventRepository"),
        new MigratedValidatedRepository(
          new ResilientRepository(
            new EncryptedRepository(
              this.EncryptedDomainEventRepository, // Repository with encrypted data
              this.EncryptionPasswordService,
            ),
            this.LogService,
            retryInterval,
          ),
          new EventMigrator(),
        ),
        this.Notifier,
        () => this.reloadEvents(),
        true,
      ),
    );

    this.EventBus = new DomainEventBus();
    this.EventStore = new EventStore(this.EventBus, this.DomainEventRepository);

    this.ContextRepository = new SingleRepository(
      new ValidatedRepository( // TODO: How to deal with migration? Reset on error or use MigratedValidatedRepository
        new IndexedDbRepository<ContextModel>("ContextRepository"),
      ),
    );

    this.SmartCaptureWebParser = new SmartCaptureWebParser(
      new BrowserWebParser(
        this.RestUtils,
        this.ConfigurationManager.baseUrlApi(),
      ),
    );
  }
}
