import { IModel } from "../../../domain/domainModels/IModel";
import { Mutable } from "../../../domain/Types";
import { ModelCloner } from "../../../util/ModelCloner";

export function renameLaterToMaybeInTaskModel(event: Mutable<IModel>) {
  if (
    event.taskModel &&
    event.taskModel.modelVersion <= 2 &&
    ModelCloner.HasProp(event.taskModel, "later")
  ) {
    event.taskModel.maybe = event.taskModel.later;
    delete event.taskModel.later;
    event.taskModel.modelVersion = 3;
  }
}
