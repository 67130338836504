import * as Uuid from "uuid";

import { IIdentifiable } from "./IIdentifiable";
import { Validate } from "../validators/Validate";

export class ContextModel implements IIdentifiable {
  public readonly modelVersion = 1;
  public readonly __type: string = "ContextModel";
  public readonly uuid: string;

  public readonly context: string = "";

  // From https://stackoverflow.com/a/37682352
  constructor(init?: Partial<ContextModel>) {
    this.uuid = Uuid.v4();
    Object.assign(this, init);
  }

  public validate() {
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.string(this.context);
  }
}
