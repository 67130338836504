import { UserModel } from "../../../domain/domainModels/UserModel";
import { Mutable } from "../../../domain/Types";

export function addUserModelType(userModel: Mutable<UserModel>) {
  if (!userModel.__type) {
    // cloneToType will also set it so this is already done at this type.
    // Keeping this code for now as clone implementations have been changed a lot
    userModel.__type = "UserModel";
  }
}
