import { ProjectModel } from "../domainModels/ProjectModel";
import { Validate } from "../validators/Validate";
import { DomainEventModel } from "./DomainEventModel";

// TODO: Forgot to make events readonly, so that must still be done. Events are also stored in repo's
export class AddProjectEventModel extends DomainEventModel {
  public __type: string = "AddProjectEventModel";
  public modelVersion = 2;
  public static eventNameConstant = "AddProjectEvent"; // Add a migration and make all eventNameConstant end in Model? Also check 20240315-addMissingTypeFields

  public eventName = AddProjectEventModel.eventNameConstant;

  protected static override expectedProperties = [
    "modelVersion",
    "__type",
    "eventName",
    "projectModel",
  ].concat(super.expectedProperties);

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, AddProjectEventModel.expectedProperties);
  }

  constructor(public projectModel: ProjectModel) {
    super();
  }

  public override validate() {
    this.validateExpectedProperties();
    this.projectModel.validate();
    super.validate();
  }
}
