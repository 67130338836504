import * as Uuid from "uuid";

import { ProjectModel } from "../domain/domainModels/ProjectModel";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DateTimeUtils } from "../util/DateTimeUtils";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { FileSystemUtils } from "../util/FileSystemUtils";
import { IFileHelper } from "../util/IFileHelper";
import { TextUtils } from "../util/TextUtils";
import { AbstractRepository } from "./AbstractRepository";
import { ModelCloner } from "../util/ModelCloner";

export class ProjectTxtRepository
  extends AbstractRepository<ProjectModel>
  implements IRepository<ProjectModel>
{
  constructor(private readonly fileHelper: IFileHelper) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async save(projectModel: ProjectModel): Promise<timestamp> {
    const projectModels = await this.getAll();
    const mutableProjectModels = projectModels.slice();

    const existingProjectIndex = this.findProjectIndex(
      mutableProjectModels,
      projectModel.uuid,
    );

    if (existingProjectIndex === -1) {
      await this.saveProjectToFile(projectModel);
    } else {
      mutableProjectModels[existingProjectIndex] = projectModel;

      await this.saveAllProjects(mutableProjectModels);
    }
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  public async getAll(): Promise<readonly ProjectModel[]> {
    const projectLines = await this.getProjectLines();

    return projectLines
      .filter((l) => {
        return l !== "";
      })
      .map((pl) => {
        return this.parseProjectLine(pl);
      });
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  public async deleteAll(): Promise<timestamp> {
    await this.fileHelper.saveTextFile("");
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  // only implemented for interface
  /* istanbul ignore next */
  public async getTimestamp(): Promise<timestamp> {
    throw new Error("Method not implemented.");
  }

  // hard to test during integration
  /* istanbul ignore next */
  public async delete(uuid: string): Promise<timestamp> {
    const projectModels = await this.getAll();
    const mutableProjectModels = projectModels.slice();
    const projectIndex = this.findProjectIndex(mutableProjectModels, uuid);
    mutableProjectModels.splice(projectIndex, 1);
    await this.saveAllProjects(mutableProjectModels);
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  public async import(models: readonly ProjectModel[]): Promise<timestamp> {
    await this.saveAllProjects(models);
    return DateTimeUtils.zeroTimestamp; // No timestamp implemented
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private findProjectIndex(
    projectModels: readonly ProjectModel[],
    uuid: string,
  ) {
    return projectModels.findIndex((pm: ProjectModel) => {
      return pm.uuid === uuid;
    });
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private async getProjectLines(): Promise<string[]> {
    try {
      const projectContent = await this.fileHelper.readFile();
      return projectContent.split(/\r?\n/);
    } catch (error) {
      FileSystemUtils.validateIsNoSuchFileOrDirectoryError(error as Error);
      return [];
    }
  }

  private parseProjectLine(projectLine: string): ProjectModel {
    const completed =
      projectLine.startsWith("x ") || projectLine.startsWith("X ");
    let remainder = projectLine;

    if (completed) {
      remainder = projectLine.substring(2);
    }

    const allTokens = /(\$)|\suuid|\screationDateTime:|\scompletionDateTime:/;
    const project = TextUtils.extractTextFragmentUntilAnyToken(
      remainder,
      allTokens,
    );
    const outcomes = TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
      remainder,
      /\$/,
      allTokens,
    );
    let uuid = TextUtils.extractSingleTextFragmentWithoutItsToken(
      remainder,
      /\suuid:/,
      allTokens,
    );
    const creationDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\screationDateTime:/,
        allTokens,
      );
    const completionDateTimeText =
      TextUtils.extractSingleTextFragmentWithoutItsToken(
        remainder,
        /\scompletionDateTime:/,
        allTokens,
      );

    if (!uuid) {
      uuid = Uuid.v4();
    }

    let projectModel = new ProjectModel({
      uuid,
      project,
      outcomes,
      completed,
    });

    const creationDateTime = parseInt(creationDateTimeText, 10);
    if (creationDateTime) {
      // At the moment we don't read or specify the creation date in todo.txt
      // (4th field in todo.txt, without the time)
      projectModel = ModelCloner.updateValues(projectModel, {
        creationDateTime,
      });
    }

    const completionDateTime = parseInt(completionDateTimeText, 10);
    if (completionDateTime) {
      // At the moment we don't read or specify the completion date in todo.txt
      // (3th field in todo.txt, without the time)
      projectModel = ModelCloner.updateValues(projectModel, {
        completionDateTime,
      });
    }

    return projectModel;
  }

  private generateProjectLine(projectModel: ProjectModel): string {
    let projectLine = "";

    if (projectModel.completed) {
      projectLine = "X ";
    }

    projectLine += projectModel.project;

    for (const outcome of projectModel.outcomes) {
      projectLine += " $" + outcome;
    }

    projectLine += " uuid:" + projectModel.uuid; // Project always has a creation date in integration test, //  if statement only used for importing an old version

    /* istanbul ignore else */
    if (projectModel.creationDateTime) {
      projectLine += " creationDateTime:" + projectModel.creationDateTime;
    }

    if (projectModel.completionDateTime) {
      projectLine += " completionDateTime:" + projectModel.completionDateTime;
    }

    projectLine = TextUtils.newLinesToSpaces(projectLine);

    projectLine += "\n";

    return projectLine;
  }

  /* istanbul ignore next */ // Not used in integration
  private async saveProjectToFile(projectModel: ProjectModel): Promise<void> {
    const projectLine = this.generateProjectLine(projectModel);
    await this.fileHelper.appendFile(projectLine);
  }

  // Not used in integration as we only use this for import export
  /* istanbul ignore next */
  private async saveAllProjects(
    projectModels: readonly ProjectModel[],
  ): Promise<void> {
    let projectText = "";
    for (const projectModel of projectModels) {
      projectText += this.generateProjectLine(projectModel);
    }
    await this.fileHelper.saveTextFile(projectText);
  }
}
