import { IModel } from "../../../domain/domainModels/IModel";
import { UpdateProjectEventModel } from "../../../domain/events/UpdateProjectEventModel";
import { Mutable } from "../../../domain/Types";

export function removeNullFromOutcomes(event: Mutable<IModel>) {
  if (
    event.eventName &&
    event.eventName === UpdateProjectEventModel.eventNameConstant
  ) {
    const project = event.projectModel;
    const newOutcomes = project.outcomes.filter(
      (outcome: unknown) => outcome !== null,
    );
    project.outcomes = newOutcomes;
  }
}
