import { TechnicalError } from "../domain/errors/TechnicalError";

export class DependencyInjectionUtils {
  // Having the wrong order of defining dependencies in the compositionroot could lead to
  // undefined values through the use of ! in the properties. This protects against that.
  public static validateDependenciesDefined(
    constructorArguments: IArguments | string[],
  ): void {
    let i = 0;
    for (const constructorArgument of constructorArguments) {
      /* istanbul ignore if */ // Doens't happen during integration
      if (constructorArgument === null || constructorArgument === undefined) {
        const errormessage =
          "Error: DI Constructor argument is not defined for argument index (zero based): " +
          i +
          " value: " +
          JSON.stringify(constructorArgument);

        const error = new TechnicalError(errormessage);
        // TODO: Now we have a circular dependency JSON.stringify faiils
        error.additionalDetails =
          "Json list of arguments: " + JSON.stringify(constructorArguments);

        throw error;
      }
      i++;
    }
  }
}
